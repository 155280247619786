// *NOTICE* This file is generated by the build, do not edit directly.
/* eslint-disable quotes, quote-props */
const mainNav = [
  {
    "href": "https://www.mass.gov/topics/living",
    "text": "Living",
    "active": false,
    "subNav": [
      {
        "href": "https://www.mass.gov/topics/health-social-services",
        "text": "Health & Social Services",
        "active": false
      },
      {
        "href": "https://www.mass.gov/topics/families-children",
        "text": "Families & Children",
        "active": false
      },
      {
        "href": "https://www.mass.gov/topics/housing-property",
        "text": "Housing & Property",
        "active": false
      },
      {
        "href": "https://www.mass.gov/topics/transportation",
        "text": "Transportation",
        "active": false
      },
      {
        "href": "https://www.mass.gov/topics/legal-justice",
        "text": "Legal & Justice",
        "active": false
      },
      {
        "href": "https://www.mass.gov/topics/public-safety",
        "text": "Public Safety",
        "active": false
      },
      {
        "href": "https://www.mass.gov/topics/energy",
        "text": "Energy",
        "active": false
      },
      {
        "href": "https://www.mass.gov/topics/environment",
        "text": "Environment",
        "active": false
      },
      {
        "href": "https://www.mass.gov/topics/taxes",
        "text": "Taxes",
        "active": false
      },
      {
        "href": "https://www.mass.gov/topics/unclaimed-property",
        "text": "Unclaimed Property",
        "active": false
      },
      {
        "href": "https://www.mass.gov/topics/vital-public-records",
        "text": "Vital & Public Records",
        "active": false
      },
      {
        "href": "https://www.mass.gov/topics/voting",
        "text": "Voting",
        "active": false
      }
    ]
  },
  {
    "href": "https://www.mass.gov/topics/working",
    "text": "Working",
    "active": false,
    "subNav": [
      {
        "href": "https://www.mass.gov/topics/business-resources",
        "text": "Business Resources",
        "active": false
      },
      {
        "href": "https://www.mass.gov/topics/professional-licenses-permits",
        "text": "Professional Licenses & Permits",
        "active": false
      },
      {
        "href": "https://www.mass.gov/topics/unemployment",
        "text": "Unemployment",
        "active": false
      },
      {
        "href": "https://www.mass.gov/topics/finding-a-job",
        "text": "Finding a Job",
        "active": false
      },
      {
        "href": "https://www.mass.gov/topics/taxes",
        "text": "Taxes",
        "active": false
      },
      {
        "href": "https://www.mass.gov/topics/workers-rights-safety",
        "text": "Workers' Rights & Safety",
        "active": false
      },
      {
        "href": "https://www.mass.gov/topics/industry-regulations",
        "text": "Industry Regulations",
        "active": false
      },
      {
        "href": "https://www.mass.gov/topics/state-employee-resources",
        "text": "For State Employees",
        "active": false
      }
    ]
  },
  {
    "href": "https://www.mass.gov/topics/learning",
    "text": "Learning",
    "active": false,
    "subNav": [
      {
        "href": "https://www.mass.gov/topics/early-childhood-education-care",
        "text": "Early Childhood Education & Care",
        "active": false
      },
      {
        "href": "https://www.mass.gov/topics/elementary-secondary-schools",
        "text": "Elementary & Secondary Schools",
        "active": false
      },
      {
        "href": "https://www.mass.gov/topics/higher-education",
        "text": "Higher Education",
        "active": false
      },
      {
        "href": "https://www.mass.gov/topics/continuing-education",
        "text": "Continuing Education",
        "active": false
      },
      {
        "href": "https://www.mass.gov/topics/for-educators-administrators",
        "text": "For Educators & Administrators",
        "active": false
      }
    ]
  },
  {
    "href": "https://www.mass.gov/topics/exploring-massachusetts",
    "text": "Visiting & Exploring",
    "active": false,
    "subNav": [
      {
        "href": "https://www.mass.gov/topics/parks-recreation",
        "text": "Parks & Recreation",
        "active": false
      },
      {
        "href": "https://www.mass.gov/topics/fishing-hunting",
        "text": "Fishing & Hunting",
        "active": false
      },
      {
        "href": "https://www.mass.gov/topics/historic-sites",
        "text": "Historic Sites",
        "active": false
      },
      {
        "href": "https://www.mass.gov/topics/arts",
        "text": "Arts",
        "active": false
      },
      {
        "href": "https://www.mass.gov/topics/family-fun",
        "text": "Family Fun",
        "active": false
      },
      {
        "href": "https://www.mass.gov/topics/travel-options",
        "text": "Travel Options",
        "active": false
      },
      {
        "href": "https://www.mass.gov/topics/seasonal-activities",
        "text": "Seasonal Activities",
        "active": false
      }
    ]
  },
  {
    "href": "https://www.mass.gov/topics/your-government",
    "text": "Your Government",
    "active": false,
    "subNav": [
      {
        "href": "https://www.mass.gov/topics/executive-branch",
        "text": "Executive ",
        "active": false
      },
      {
        "href": "https://www.mass.gov/topics/constitutionals-independents",
        "text": "Constitutionals & Independents",
        "active": false
      },
      {
        "href": "https://www.mass.gov/topics/legislative-branch",
        "text": "Legislative",
        "active": false
      },
      {
        "href": "https://www.mass.gov/topics/judicial-branch",
        "text": "Judicial",
        "active": false
      },
      {
        "href": "https://www.mass.gov/topics/cities-towns",
        "text": "Cities & Towns",
        "active": false
      },
      {
        "href": "https://www.mass.gov/info-details/massachusetts-state-organizations-a-to-z",
        "text": "State Offices & Courts A-Z",
        "active": false
      }
    ]
  },
  {
    "href": "https://www.mass.gov/covid-19-updates-and-information",
    "text": "COVID-19",
    "active": false,
    "subNav": []
  }
];
export default { mainNav };
