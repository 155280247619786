import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import {
  ColoredHeading, CompHeading, Button
} from '@massds/mayflower-react';

import ThresholdScaleMap from '../components/ThresholdScaleMap';
import InTextButton from '../components/Button/InTextButton';
import FootNoteLink from '../components/FootNote/FootNoteLink';

import { getObjByValue, customFormat1 } from '../utils';
import { speedMapsOptions } from './mapOptions.data';
import content from '../data/Content.data';

const {
  data: {
    averageMiles, averageDuration, averageSpeed
  }
} = content;

const SpeedSection = (props) => {
  const {
    allData, geography, areas
  } = props;
  const [option, setOption] = useState(speedMapsOptions[0].origin);
  const setOptionFromName = (name) => {
    const opt = getObjByValue(speedMapsOptions, name, 'name');
    setOption(opt.origin);
  };
  return(
    <Fragment>
      <ColoredHeading text="Speed and Length of Rides" color="blue" />
      <aside className="sidebar sidebar-wide">
        <ul>
          <li>
            Overall, in 2019, rides traveled shorter distances, lasted longer, and moved more slowly compared to rides in 2018 and 2017.
          </li>
          <li>
            {'The average ride in Massachusetts '}
            <InTextButton onClick={() => setOptionFromName('Minutes from Start')}>
              {`lasted ${averageDuration} minutes`}
            </InTextButton>
            {' and '}
            <InTextButton onClick={() => setOptionFromName('Miles from Start')}>
              {`traveled ${averageMiles} miles`}
            </InTextButton>
            {' at '}
            <InTextButton onClick={() => setOptionFromName('Miles per Hour')}>
              {`${averageSpeed} miles per hour`}
            </InTextButton>
            .
            <FootNoteLink index={6} />
          </li>
          <li>
            Rides in Central and Western Massachusetts tended to move faster than in Eastern Massachusetts. Municipalities that averaged speeds of more than 40 miles per hour were all located in Central and Western Massachusetts.
          </li>
          <li>
            The slowest travel speeds were in densely populated cities and towns. Watertown, Cambridge, Somerville, and Brookline all had average ride speeds of less than 14 miles per hour.
          </li>
        </ul>
      </aside>
      <div className="page-content page-content-narrow">
        <div className="map-print">
          <CompHeading title={`Rideshare ${option.title} by Municipality`} level={3} centered />
          <div className="wrapperButtonStyles data-change">
            {
                speedMapsOptions && speedMapsOptions.map((opt, i) => (
                  <Button
                    text={opt.name}
                    info={`Change map to display ${option.name}`}
                    usage={opt.origin.field === option.field ? '' : 'secondary'}
                    onClick={() => setOption(opt.origin)}
                    // eslint-disable-next-line react/no-array-index-key
                    key={`speedmap_button-${i}`}
                  />
                ))
              }
          </div>
          <ThresholdScaleMap
            showZoom
            dataField={option.field}
            dataUnits={option.units}
            dataFormat={customFormat1}
            scaleBreaks={option.scale}
            nullValue={0}
            scaleColors={option.colorScale}
            allData={allData}
            geography={geography}
            legend={{
              direction: window.innerWidth > 620 ? 'column' : 'row',
              title: option.legendTitle,
              itemDirection: window.innerWidth > 620 ? 'row' : 'column'
            }}
            tooltip={{ dataLabel: option.dataLabel }}
            areas={areas}
          />
        </div>
      </div>
    </Fragment>
  );
};

SpeedSection.propTypes = {
  allData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  geography: PropTypes.object,
  areas: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    coordinates: PropTypes.array,
    zoom: PropTypes.number
  }))
};

export default SpeedSection;
