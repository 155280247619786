import UtilityNavData from './UtilityNav.data';
import MainNavData from './MainNav.data';
import HeaderSearchData from './HeaderSearch.data';
import FooterLinksLiveData from './FooterLinksLive.json';
import SocialLinksLiveData from './SocialLinksLive.json';
import image from '../media/shutterstock_629908583.jpg';
import { inBil, inMil, deltaPct } from '../utils';

const d = {
  totalRidesOrigin: 91082437,
  totalRidesOrigin2018: 81312652,
  totalRidesOrigin2017: 64797875,
  totalFee: 18216487.40,
  averageMiles: 4.2,
  averageDuration: 14.8,
  averageSpeed: 17.1,
  // need to update
  houseVehicleTrips: 7000000000,
  publicTransitTrips: 300164777,
  cambridge: {
    originPP: 74.9,
    destinationPP: 72.8
  },
  boston: {
    originPP: 73.3,
    destinationPP: 73.5
  },
  nantucket: {
    originPP: 36.0,
    destinationPP: 36.0
  },
  hadley: {
    originPP: 11.3,
    destinationPP: 10.3
  },
  amherst: {
    originPP: 9.0,
    destinationPP: 9.0
  }
};

const data = {
  totalRidesInMil: inMil(d.totalRidesOrigin),
  totalRidesInMil2018: inMil(d.totalRidesOrigin2018),
  totalRidesInMil2017: inMil(d.totalRidesOrigin2017),
  totalFeeInMil: inMil(d.totalFee),
  totalRidesDeltaPct2018: deltaPct(d.totalRidesOrigin, d.totalRidesOrigin2018),
  totalRidesDeltaPct2017: deltaPct(d.totalRidesOrigin, d.totalRidesOrigin2017),
  deltaTopCities: d.deltaTopCities,
  deltaTopSmallCities: d.deltaTopSmallCities,
  houseVehicleTripsInBil: inBil(d.houseVehicleTrips),
  publicTransitTripsInMil: inMil(d.publicTransitTrips),
  ...d
};


const footerProps = {
  footerLinks: FooterLinksLiveData.footerLinks,
  socialLinks: SocialLinksLiveData.socialLinks
};

const headerProps = {
  utilityNav: UtilityNavData,
  headerSearch: HeaderSearchData,
  mainNav: MainNavData,
  hideHeaderSearch: true,
  hideBackTo: true,
  siteLogoDomain: { url: { domain: 'https://www.mass.gov/' } }
};

const illustratedHeader = {
  bgInfo: 'rideshare placeholder image',
  bgImage: image,
  category: '2019 Data Report',
  inverted: false,
  publishState: {
    text: null
  },
  pageHeader: {
    title: 'Rideshare in Massachusetts',
    subTitle: '',
    optionalContents: [{
      paragraph: {
        text: `In 2019, rideshare companies – also called transportation network companies (TNCs) – provided <b>${data.totalRidesInMil} million</b> rides in Massachusetts, approximately  ${data.totalRidesDeltaPct2018}% more than in 2018 and ${data.totalRidesDeltaPct2017}% more than in 2017. This increase happened across the state, in towns and cities of all sizes and types.<br /><br />Massachusetts law requires rideshare companies to share data with the Commonwealth. The TNCs also pay an assessment of 20 cents per ride that is distributed among cities and towns, Massachusetts’s general transportation fund, and MassDevelopment to assist small businesses operating in the taxicab, livery, or hackney industries. More than $12 million was collected in 2017, more than $16 million was collected in 2018, and more than $${data.totalFeeInMil} million was collected in 2019. You can see how your municipality allocated the funds it received for rides taken <a href="https://www.mass.gov/info-details/2017-municipal-rideshare-fund-report">in 2017</a> and <a href="https://www.mass.gov/info-details/2018-municipal-rideshare-fund-report">in 2018</a>.<br /><br />This report includes an interactive summary of the 2019 rideshare data. You can explore how people in your city or town use rideshare services, such as how many rides began where you live and where those rides ended. You’ll also learn how population density and other factors affect ridesharing. You can also <a href="./data/TNC_Dataset_2019_for_download.xlsx">download data behind this summary</a>, and view <a href="https://tnc.sites.digital.mass.gov/2017/">the 2017 report</a> and <a href="https://tnc.sites.digital.mass.gov/2018/">the 2018 report.</a>`
      }
    }],
    headerTags: null,
    publishState: null
  }
};

export default {
  data,
  footerProps,
  headerProps,
  illustratedHeader
};
