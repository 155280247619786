import React from 'react';
import PropTypes from 'prop-types';
import { animatePageScroll } from './scroll';

import './index.css';

const FootNote = (props) => {
  // eslint-disable-next-line react/prop-types
  const { children, i } = props;

  return(
    <li>
      <div
        id={`footnotemsg${i}`}
        onKeyPress={(event) => {
          if (event.key === 'Enter') { animatePageScroll(i, 70, `#footnoteref${i}`); }
        }}
        onClick={() => animatePageScroll(i, 70, `#footnoteref${i}`)}
        role="button"
        tabIndex="0"
      >
        <span>{`${children} `}</span>
        <span>&uarr;</span>
      </div>
    </li>
  );
};

FootNote.propTypes = {
  i: PropTypes.number
};

const FootNotes = () => (
  <div className="main-content sources-section">
    <div className="main-content--two">
      <h1 id="footnote-label" className="sr-only">Footnotes</h1>
      <ol className="page-content">
        <FootNote i={1}>Data pertains only to TNC rides that took place transporting individuals. Other TNC behaviors, such as travel without a passenger or food delivery are not included in this report. </FootNote>
        <FootNote i={2}>Estimate based on MassDOT’s 2011 Household Travel Survey and the UMass Donahue Institute’s 2018 Long-Term Population Projections for Massachusetts Regions and Municipalities.</FootNote>
        <FootNote i={3}>Data provided by Massachusetts Department of Transportation (see https://massdottracker.com for more infomration).</FootNote>
        <FootNote i={4}>Data provided by Boston Logan International Airport. </FootNote>
        <FootNote i={5}>Population data to determine per person rates are from the 2010 American Community Survey (ACS) published by the U.S. Census Bureau.</FootNote>
        <FootNote i={6}>Averages were calculated by weighting TNC-reported trip length and distance by ride counts.</FootNote>
      </ol>
    </div>
  </div>
);

export default FootNotes;
